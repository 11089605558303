<template>
  <div class="d-flex flex-grow-1 action" @click.stop="clicked">
    <div>
      <h5 class="mb-0 font-weight-bolder toastification-title text-primary">New Message: {{ fullName }}</h5>
      <small class="d-inline-block text-body">{{ message }}</small>
    </div>
    <span class="cursor-pointer toastification-close-icon ml-auto ">
      <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-body feather feather-x"><line data-v-7f5431ea="" x1="18" y1="6" x2="6" y2="18"></line><line data-v-7f5431ea="" x1="6" y1="6" x2="18" y2="18"></line></svg>
    </span>
  </div>
</template>
<script>
export default {
  props: ["fullName","message"],
  methods: {
    clicked() {
      // Emit a "click" event when clicked.
      // Can be any event though and even pass parameters back
      this.$emit("click");
    }
  }
};
</script>
<style>
</style>