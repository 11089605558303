<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.image"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0 cursor-pointer" @click="shallShowActiveChatContactSidebar=true">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center" v-if="activeChat.contact">
              <b-button
                class="float-right"
                variant="primary"
                type="submit"
                :to="{name: 'apps-users-view', params: { id: activeChat.contact.username }}"
              >
                Book Meeting
              </b-button>
              <!-- <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              /> -->
              <!-- <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              /> -->
              <!-- <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div> -->
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
              autocomplete="off"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="chatsContacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatToastMessageView from './ChatToastMessageView.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, updateDoc, arrayUnion, getDocs, query, where, orderBy, setDoc, onSnapshot, doc, limit, serverTimestamp, increment} from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions()
const db = getFirestore()
const auth = getAuth()

export default {
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  data() {
   return {
      chats: [],
      //chatsContacts: [],
      contacts: [],
      error:"",
   }
  },
  setup() {
    const currentUser = JSON.parse(localStorage.getItem('userData'))
    currentUser.username = auth.currentUser.displayName
    const chatsContacts = ref([])
    var messageSnapShot = null

    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
      messageSnapShot() // Unsubscibe from snapshot messaged
    })

    const { resolveAvatarBadgeVariant } = useChat()
    const toast = useToast()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // Create an Chat ID
    const createChatID = (senderId, receiverId) => {
      // Check if currentUserID id is less than receiverID
      if(senderId < receiverId)
        return senderId+receiverId  
      else
        return receiverId+senderId
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const fetchChatAndContacts = () => {
      getDocs(collection(db, "profiles", auth.currentUser.displayName, "contacts")).then(snapshot => {
        snapshot.forEach(doc => {
          let contact = doc.data()
          contact.id = doc.id
          contact.date = (doc.data().lastMessageTime)?doc.data().lastMessageTime.toDate():''
          contact.messages = []
          chatsContacts.value.push(contact)
        })
        messageSnapShot = getContactsMessages()
        setTimeout(() => { checkNewContact() }, 500);//TODO fix this
      })
    }

    const getContactsMessages = () => {
      // Get realtime updates when a message is sent
      // TODO Paginate
      return onSnapshot(query(collection(db, "chats"), where("users",  "array-contains", auth.currentUser.uid), limit(50)), (snapshot) => {

        // Realtime update if we have a new message
        snapshot.docChanges().forEach((change) => {
          let messageGroup = change.doc.data()
          messageGroup.id = change.doc.id
          
          const contactIndex =  chatsContacts.value.findIndex(c => messageGroup.users.includes(c.id))
          if(contactIndex > -1) chatsContacts.value[contactIndex].messages = messageGroup.messages
          
          const lastMessage = messageGroup.messages[messageGroup.messages.length-1]
          if (change.type === "modified" && lastMessage.id !== auth.currentUser.uid) {
            console.log('show notifcaion')

            // Play notifcation
            const notifcaionSound = require("@/assets/sounds/ring.mp3")
            const audio = new Audio(notifcaionSound)
            audio.play()

            // Update chat messages
            activeChat.value = { chat:chatsContacts.value[contactIndex].messages, contact:chatsContacts.value[contactIndex] }

            // Scroll to bottom
            nextTick(() => { scrollToBottomInChatLog() })

            // Show new message notifcation
            const content = {
              component: ChatToastMessageView,
              props: { 
                fullName: chatsContacts.value[contactIndex].fullName,
                message: messageGroup.messages[messageGroup.messages.length-1].text 
              },
              listeners: {
                click: () => { 
                  activeChat.value = { chat:chatsContacts.value[contactIndex].messages, contact:chatsContacts.value[contactIndex]}
                }
              }
            }
            toast(content,{
              component: ToastificationContent,
              position: 'top-right',
            },{timeout: 5000})
          }
        })
      })
    }

    const checkNewContact = () => {
      // if there is a username auto select chat
      if(router.currentRoute.params.id){
        const contact = chatsContacts.value.find(c => c.username == router.currentRoute.params.id)

        // Make sure we have a contact and open it
        if(contact) openChatOfContact(contact.id)
      }
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      
      // Reset send message input value
      chatInputMessage.value = ''

      const activeContact =  chatsContacts.value.find(c => c.id === userId)
      activeChat.value = { chat:activeContact.messages, contact:activeContact }

      // Set unseenMsgs to 0
      // const contact = chatsContacts.value.find(c => c.id === userId)
      // if (contact) contact.chat.unseenMsgs = 0

      // Scroll to bottom
      nextTick(() => { scrollToBottomInChatLog() })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      if(mqShallShowLeftSidebar) mqShallShowLeftSidebar.value = false
    }

    const sendMessage = async() => {
      if (!chatInputMessage.value) return

      const chatID = createChatID(currentUser.uid, activeChat.value.contact.id)
      const data = {
        id: currentUser.uid,
        username: currentUser.username,
        text: chatInputMessage.value,
        createdAt: Date.now(),
        fullName: currentUser.fullName,
        image: encodeURIComponent(currentUser.image),
      }

      // Add message to log
      activeChat.value.chat.push(data)

      // Set Last Message for active contact
      const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
      contact.lastMessage = chatInputMessage.value
      contact.date = Date.now()
      contact.messages = activeChat.value.chat

      // Reset send message input value
      chatInputMessage.value = ''

      // Scroll to bottom
      nextTick(() => { scrollToBottomInChatLog() })

      const sendChatMessage = httpsCallable(functions, 'sendChatMessage')
      const resp = await sendChatMessage({chatID:chatID, to:activeChat.value.contact.username, contactID:activeChat.value.contact.id, messageData: data})
      // console.log(resp)
      // if(resp && !resp.data.success) // TODO
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})
    profileUserDataMinimal.value = { 
      id: currentUser.id,
      avatar: currentUser.image,
      fullName: 'Robert Smith',
      status: 'active',
    }

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      //contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
